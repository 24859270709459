import React from 'react';

const Preloader = ({ varient }) => {
  return (
    <div className="h-screen w-screen flex items-center justify-center">
          <div className="h-[60%] w-[60%] mx-auto my-auto">
            <img src="/logo.gif" alt="Logo"  />
          </div>
    </div>
  )
}

export default Preloader
