import React from 'react';
import SectionHeading from '../SectionHeading/SectionHeading';
import physio from "./services/physio.jpg"
import adult from "./services/adult.jpg"
import autism from "./services/autism.jpg"
import family from "./services/family.jpg"
import group from "./services/group.jpg"
import parent from "./services/ParentTraning.jpg"
import psychological from "./services/psycological.jpg"
import school from "./services/schoolTraining.jpg"
import speech from "./services/speech.jpg"

const Department = () => {
  return (
    <section id="department">
      <div className="st-height-b120 st-height-lg-b80" />
      <SectionHeading title='Our Services'
        subTitle=
        "Our mission is to empower our little warriors to become confident, curious, and compassionate individuals." />
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-16 mx-auto">
          <div className="flex flex-wrap -m-4">
            <div className="p-4 md:w-1/3">
              <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden shadow-[0px_0px_0px_1px_rgba(0,0,0,0.06),0px_1px_1px_-0.5px_rgba(0,0,0,0.06),0px_3px_3px_-1.5px_rgba(0,0,0,0.06),_0px_6px_6px_-3px_rgba(0,0,0,0.06),0px_12px_12px_-6px_rgba(0,0,0,0.06),0px_24px_24px_-12px_rgba(0,0,0,0.06)]">
                <img className="lg:h-72 md:h-52 w-full object-cover object-center" src={speech} alt="blog" />
                <div className="p-6">
                  <h2 className="tracking-widest text-xs title-font font-semibold text-[#94D7C9] mb-1">THERAPY</h2>
                  <h1 className="title-font text-lg font-semibold text-[#5F995F] mb-3 ">Speech Therapy</h1>
                  <p className="leading-relaxed mb-3">Enhance your child’s communication abilities with our expert speech therapy services.</p>
                </div>
              </div>
            </div>
            <div className="p-4 md:w-1/3">
              <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden shadow-[0px_0px_0px_1px_rgba(0,0,0,0.06),0px_1px_1px_-0.5px_rgba(0,0,0,0.06),0px_3px_3px_-1.5px_rgba(0,0,0,0.06),_0px_6px_6px_-3px_rgba(0,0,0,0.06),0px_12px_12px_-6px_rgba(0,0,0,0.06),0px_24px_24px_-12px_rgba(0,0,0,0.06)]">
                <img className="lg:h-72 md:h-52 w-full object-cover object-center" src={autism} alt="blog" />
                <div className="p-6">
                  <h2 className="tracking-widest text-xs title-font font-semibold text-[#94D7C9] mb-1">THERAPY</h2>
                  <h1 className="title-font text-lg font-semibold text-[#5F995F] mb-3 ">Autism Therapy</h1>
                  <p className="leading-relaxed mb-3">At Little Warrior, we specialize in evidence-based autism therapy, providing personalized interventions to support children on the autism spectrum.</p>
                </div>
              </div>
            </div>
            <div className="p-4 md:w-1/3">
              <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden shadow-[0px_0px_0px_1px_rgba(0,0,0,0.06),0px_1px_1px_-0.5px_rgba(0,0,0,0.06),0px_3px_3px_-1.5px_rgba(0,0,0,0.06),_0px_6px_6px_-3px_rgba(0,0,0,0.06),0px_12px_12px_-6px_rgba(0,0,0,0.06),0px_24px_24px_-12px_rgba(0,0,0,0.06)]">
                <img className="lg:h-72 md:h-52 w-full object-cover object-center" src={group} alt="blog" />
                <div className="p-6">
                  <h2 className="tracking-widest text-xs title-font font-semibold text-[#94D7C9] mb-1">INTERVENTION</h2>
                  <h1 className="title-font text-lg font-semibold text-[#5F995F] mb-3 ">Group Intervention</h1>
                  <p className="leading-relaxed mb-3">We believe in collaborative learning. Our group teaching sessions create a dynamic and interactive learning environment.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="text-gray-600 body-font">
        <div className="container px-5 mx-auto">
          <div className="flex flex-wrap -m-4">
            <div className="p-4 md:w-1/3">
              <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden shadow-[0px_0px_0px_1px_rgba(0,0,0,0.06),0px_1px_1px_-0.5px_rgba(0,0,0,0.06),0px_3px_3px_-1.5px_rgba(0,0,0,0.06),_0px_6px_6px_-3px_rgba(0,0,0,0.06),0px_12px_12px_-6px_rgba(0,0,0,0.06),0px_24px_24px_-12px_rgba(0,0,0,0.06)]">
                <img className="lg:h-72 md:h-52 w-full object-cover object-center" src={psychological} alt="blog" />
                <div className="p-6">
                  <h2 className="tracking-widest text-xs title-font font-semibold text-[#94D7C9] mb-1">COUNSELING</h2>
                  <h1 className="title-font text-lg font-semibold text-[#5F995F] mb-3 ">Psychological Counseling</h1>
                  <p className="leading-relaxed mb-3">Our experienced psychologists provide counseling services to support both children and their families, addressing emotional needs and promoting resilience.</p>
                </div>
              </div>
            </div>
            <div className="p-4 md:w-1/3">
              <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden shadow-[0px_0px_0px_1px_rgba(0,0,0,0.06),0px_1px_1px_-0.5px_rgba(0,0,0,0.06),0px_3px_3px_-1.5px_rgba(0,0,0,0.06),_0px_6px_6px_-3px_rgba(0,0,0,0.06),0px_12px_12px_-6px_rgba(0,0,0,0.06),0px_24px_24px_-12px_rgba(0,0,0,0.06)]">
                <img className="lg:h-72 md:h-52 w-full object-cover object-center" src={parent} alt="blog" />
                <div className="p-6">
                  <h2 className="tracking-widest text-xs title-font font-semibold text-[#94D7C9] mb-1">TEACHING</h2>
                  <h1 className="title-font text-lg font-semibold text-[#5F995F] mb-3 ">Parent Training</h1>
                  <p className="leading-relaxed mb-3">We believe in collaborative learning. Our group teaching sessions create a dynamic and interactive learning environment.</p>
                </div>
              </div>
            </div>
            <div className="p-4 md:w-1/3">
              <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden shadow-[0px_0px_0px_1px_rgba(0,0,0,0.06),0px_1px_1px_-0.5px_rgba(0,0,0,0.06),0px_3px_3px_-1.5px_rgba(0,0,0,0.06),_0px_6px_6px_-3px_rgba(0,0,0,0.06),0px_12px_12px_-6px_rgba(0,0,0,0.06),0px_24px_24px_-12px_rgba(0,0,0,0.06)]">
                <img className="lg:h-72 md:h-52 w-full object-cover object-center" src={school} alt="blog" />
                <div className="p-6">
                  <h2 className="tracking-widest text-xs title-font font-semibold text-[#94D7C9] mb-1">TEACHING</h2>
                  <h1 className="title-font text-lg font-semibold text-[#5F995F] mb-3 ">Teacher and School Training</h1>
                  <p className="leading-relaxed mb-3">Little Warrior extends its expertise to educators and schools through specialized training programs.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="text-gray-600 body-font">
        <div className="container px-5 mx-auto">
          <div className="flex flex-wrap -m-4">
            <div className="p-4 md:w-1/3">
              <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden shadow-[0px_0px_0px_1px_rgba(0,0,0,0.06),0px_1px_1px_-0.5px_rgba(0,0,0,0.06),0px_3px_3px_-1.5px_rgba(0,0,0,0.06),_0px_6px_6px_-3px_rgba(0,0,0,0.06),0px_12px_12px_-6px_rgba(0,0,0,0.06),0px_24px_24px_-12px_rgba(0,0,0,0.06)]">
                <img className="lg:h-72 md:h-52 w-full object-cover object-center" src={physio} alt="blog" />
                <div className="p-6">
                  <h2 className="tracking-widest text-xs title-font font-semibold text-[#94D7C9] mb-1">THERAPY</h2>
                  <h1 className="title-font text-lg font-semibold text-[#5F995F] mb-3 ">Physio Therapy</h1>
                  <p className="leading-relaxed mb-3">Our experienced psychologists provide counseling services to support both children and their families, addressing emotional needs and promoting resilience.</p>
                </div>
              </div>
            </div>
            <div className="p-4 md:w-1/3">
              <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden shadow-[0px_0px_0px_1px_rgba(0,0,0,0.06),0px_1px_1px_-0.5px_rgba(0,0,0,0.06),0px_3px_3px_-1.5px_rgba(0,0,0,0.06),_0px_6px_6px_-3px_rgba(0,0,0,0.06),0px_12px_12px_-6px_rgba(0,0,0,0.06),0px_24px_24px_-12px_rgba(0,0,0,0.06)]">
                <img className="lg:h-72 md:h-52 w-full object-cover object-center" src={adult} alt="blog" />
                <div className="p-6">
                  <h2 className="tracking-widest text-xs title-font font-semibold text-[#94D7C9] mb-1">COUNSELLING</h2>
                  <h1 className="title-font text-lg font-semibold text-[#5F995F] mb-3 ">Adult counselling </h1>
                  <p className="leading-relaxed mb-3">We believe in collaborative learning. Our group teaching sessions create a dynamic and interactive learning environment.</p>
                </div>
              </div>
            </div>
            <div className="p-4 md:w-1/3">
              <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden shadow-[0px_0px_0px_1px_rgba(0,0,0,0.06),0px_1px_1px_-0.5px_rgba(0,0,0,0.06),0px_3px_3px_-1.5px_rgba(0,0,0,0.06),_0px_6px_6px_-3px_rgba(0,0,0,0.06),0px_12px_12px_-6px_rgba(0,0,0,0.06),0px_24px_24px_-12px_rgba(0,0,0,0.06)]">
                <img className="lg:h-72 md:h-52 w-full object-cover object-center" src={family} alt="blog" />
                <div className="p-6">
                  <h2 className="tracking-widest text-xs title-font font-semibold text-[#94D7C9] mb-1">THERAPY</h2>
                  <h1 className="title-font text-lg font-semibold text-[#5F995F] mb-3 ">Family and couple therapy</h1>
                  <p className="leading-relaxed mb-3">Little Warrior extends its expertise to educators and schools through specialized training programs.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="st-height-b120 st-height-lg-b80" />
    </section>
  );
};

export default Department;
