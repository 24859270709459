import { Icon } from '@iconify/react';
import React from 'react';

// Utility function to limit subtitle length
const limitWords = (text, maxWords) => {
  const words = text.split(' ');
  if (words.length > maxWords) {
    return words.slice(0, maxWords).join(' ') + '...';
  }
  return text;
};

const Testimonial = ({ img, name, designation, subTitle, varient, maxWords = 30 }) => {
  // Truncate the subtitle based on maxWords
  const truncatedSubTitle = limitWords(subTitle, maxWords);

  return (
    <div className={`st-testimonial st-style1 ${varient} wow fadeInLeft`} data-wow-duration="0.8s" data-wow-delay="0.2s">
      <div className="st-testimonial-info">
        {/* <div className="st-testimonial-img">
          <img src={img} alt={name} />
        </div> */}
        <div className="st-testimonial-meta">
          <h4 className="st-testimonial-name">{name}</h4>
          <div className="st-testimonial-designation">{designation}</div>
        </div>
      </div>
      <div className="st-testimonial-text">{truncatedSubTitle}</div>
      <div className="st-quote">
        <Icon icon="fa-solid:quote-right" />
      </div>
    </div>
  );
}

export default Testimonial;
