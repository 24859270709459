import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';

const Footer = ({ data, varient }) => {
  const { bgImg } = data;
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const [scrollPosition, setScrollPosition] = useState(0);


  const handleScroll = () => {
    const currentPosition = window.scrollY;
    setScrollPosition(currentPosition);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
      <footer className={`st-site-footer st-sticky-footer st-dynamic-bg ${varient ? varient : ""}`}
        style={{ backgroundImage: `url(${bgImg})` }}>
        <div className="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col ">
          <div className='flex items-center'>
          <a href="/" className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
            <img src="/images/logo.svg" alt="logo" className="w-20 h-20" />
            <span className="ml-3 text-xl">Little Warrior</span>
          </a>
          
          </div>
          <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start items-center gap-2">
            <a href="https://www.instagram.com/littlewarrior_cdc?igsh=ajVrdGF4dzBlYzVj" target='_blank' rel='noreferrer' className="ml-3 text-gray-500">
              <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} className="w-5 h-5" viewBox="0 0 24 24">
                <rect width={20} height={20} x={2} y={2} rx={5} ry={5} />
                <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01" />
              </svg>
            </a>
            <div className="st-right-copyright">
              <div id="st-backtotop" style={{ scale: `${scrollPosition >= 100 ? "1" : "0"}` }} onClick={scrollToTop}>
                <Icon icon="fa6-solid:angle-up" />
              </div>
            </div>
          </span>
        </div>
      </footer>
  )
}

export default Footer
