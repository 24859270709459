import React from 'react'
import Hero3 from '../../Components/Hero/Hero3';
import Department from '../../Components/Department/Department';
import SpecialistsSlider from '../../Components/Slider/SpecialistsSlider';
import Appointment from '../../Components/Appointment/Appointment';
import TestimonialSlider from '../../Components/Slider/TestimonialSlider';
import Spacing from '../../Components/Spacing/Spacing';
import About6 from '../../Components/About/About6';


const heroData = [
  {
    title: "Welcome to little warrior child development center",
    subTitle: "At Little Warrior Children Development Center hyderabad telangana we are dedicated to <br /> nurturing young minds and fostering holistic growth in a secure and <br /> stimulating environment",
    bgImg: '/images/hero-bg13.jpg',
  },
];

const aboutData = {
  title:
    'Discover Our Story: Providing Quality Early Childhood Education in the Heart of Hyderabad',
  subTitle:
    'Little Warrior Child Development Center in Himayatnagar, Hyderabad autism, speech, occupational, behavioral, parental,counsling,group therapy in himayatnagar hyderabad! We are your dedicated partner in fostering holistic growth for your child. Our center offers a diverse range of therapeutic and educational services tailored to meet the unique needs of each little warrior. <br /><br /> Little Warrior Child Development Center in Himayatnagar, Hyderabad autism, speech, occupational, behavioral, parental,counsling,group therapy in himayatnagar hyderabad! We are your dedicated partner in fostering holistic growth for your child. Our center offers a diverse range of therapeutic and educational services tailored to meet the unique needs of each little warrior.             At Little Warrior Child Development Center, we are committed to nurturing the potential within each child. Our comprehensive approach to child development sets us apart, ensuring that every little warrior receives the care, attention, and support they deserve. Join us on this journey of growth, learning, and empowerment. Contact us today to embark on a path to a brighter future for your child. ',

  avater: {
    img: '/images/avatar1.png',
    name: 'David Ambrose',
    designation: 'Founder & Director',
  },
  timeTable: [
    {
      day: 'Monday',
      startTime: '10:00',
      endTime: '6:00',
    },
    {
      day: 'Tuesday',
      startTime: '10:00',
      endTime: '6:00',
    },
    {
      day: 'Wednesday',
      startTime: '10:00',
      endTime: '6:00',
    },
    {
      day: 'Thursday',
      startTime: '10:00',
      endTime: '6:00',
    },
    {
      day: 'Friday',
      startTime: '10:00',
      endTime: '6:00',
    },
    {
      day: 'Saturday',
      startTime: '10:00',
      endTime: '6:00',
    },
  ],
  contact: '(+91) - 77806 93882 , 73869 59494',
};

const specialistData = [
  {
    img: '/doctor/avatar2.jpg',
    name: 'Jatin',
    designation: 'Speech Theraphy',
  },
  {
    img: '/doctor/avatar3.jpg',
    name: 'Sreevidya',
    designation: 'Physiotherapist & Occupational therapist',
  },
  {
    img: '/doctor/avatar.jpeg',
    name: 'Shivani',
    designation: 'Psychologist & Behaviour therapist',
  },
  {
    img: '/doctor/avatar1.jpeg',
    name: 'Mahesh',
    designation: 'speech therapist',
  },
];

const testimonialData = [
  {
    img: '/images/avatar2.png',
    name: 'Junnu Sarusha',
    designation: 'Google Review',
    subTitle:
      "I had good experience in the journey with Little Warrior. I thought I will always be stuck at a point but after joining the Therapy in little warrior I got to know that I need to live for myself, Thank you Little Warrior CDC.",
  },
  {
    img: '/images/avatar3.png',
    name: 'Dipika dayam',
    designation: 'Google Review',
    subTitle:
      "I recently got connected to this place. Amazing staff. they are so good at what they are doing. All three therapist are so great!! They treat my child like thier own😊😊 highly recommended for your child's development 👍👍👍",
  },
  {
    img: '/images/avatar4.png',
    name: 'Diva S',
    designation: 'Google Review',
    subTitle:
      "BEST BEST BEST!!!! We’ve had an incredible experience with this school’s therapy program, where the loving and caring staff have truly made a difference in our children’s lives. The therapists, especially Shivani, Nafna, Sri Vidya, and the efficient coordination by Sunny on the management side, have created a nurturing environment that feels like a second home. Their dedication and expertise have led to significant improvements in our children’s speech, occupational, and behavioral skills. The positive changes in their abilities and confidence are a testament to the team’s hard work and the supportive, engaging atmosphere they’ve cultivated. We are deeply grateful to the entire staff for their compassion and commitment. Highly recommend this program to any parent looking for a caring and effective therapy experience for their child",
  },
  {
    img: '/images/avatar2.png',
    name: 'Rohit namepalli',
    designation: 'Google Review',
    subTitle:
      "I would highly recommend little warrior CDC for your child’s therapy.We have worked with Shivani prior to us moving to a different location. She is one of the most friendly, empathetic, understanding therapists out there and spends a great deal of time assessing the child and work on the child’s behavioral issues while keeping the child happy.",
  },
];

const Multipage = () => {
  return (
    <>
      <Hero3 data={heroData} />
      <About6 data={aboutData} />
      {/* <Iconbox data={iconboxData} varient="st-type2" /> */}
      <Spacing lg={120} md={80} />
      <hr />
      <Department />
      <hr />
      <SpecialistsSlider data={specialistData} />
      <Appointment />
      <TestimonialSlider data={testimonialData} />
    </>
  )
}

export default Multipage
