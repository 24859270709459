import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import PageNotFound from './Components/404/PageNotFound';
import Multipage from './Pages/Multipage/Multipage';
import Layout7 from './Components/Layout/Layout7';
// import AboutPage from './Pages/Multipage/AboutPage';
import GalleryPage from './Pages/Multipage/GalleryPage';
// import PricingPage from './Pages/Multipage/PricingPage';
import ContactPage from './Pages/Multipage/ContactPage';
import "./global.css"

const App = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Layout7 />}>
        <Route index element={<Multipage />} />
        <Route path='home' element={<Multipage />} />
        {/* <Route path='about' element={<AboutPage />} /> */}
        <Route path='gallery' element={<GalleryPage />} />
        {/* <Route path='pricing' element={<PricingPage />} /> */}
        <Route path="contact" element={<ContactPage />} />
        {/* <Route path="post" element={<PostPage />} /> */}
        {/* <Route path="post/:postId" element={<PostDetails />} /> */}
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};

export default App;
